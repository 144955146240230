/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import wizzlogo from "../img/wizz-new-logo.svg";
import { Link, useLocation } from "react-router-dom";
import useEncryption from "../EncryptData/EncryptData";
import instance from "../BaseUrl/BaseUrl";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { fetchData } from "../Feature/User";
import Swal from "sweetalert2";
import { ethers } from "ethers";
import { ABIs, ABI, claimABI } from "../../ABI";
import clipboardCopy from "clipboard-copy";
import { MdContentCopy } from "react-icons/md";
import Web3 from "web3";
import { Circles } from "react-loader-spinner";
const networks = {
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://bsc-dataseed.binance.org/",
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};
const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");

    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName],
        },
      ],
    });
  } catch (err) {
    setError(err.message);
  }
};

function Logo() {
  const [unstakeBalance, setUnstakeBalance] = useState(0)
  console.log("🚀 ~ unstakeBalance:", unstakeBalance)

  const [wecUnstaked, setwecUnstaked] = useState()
  const [address, setaddress] = useState("");
  // console.log("🚀 ~ address:", address)
  const [isLogin, setisLogin] = useState(!localStorage.getItem("metamask"));
  const dispatch = useDispatch();
  // console.log("🚀 ~ Logo ~ address:", address);
  const location = useLocation();
  const { pathname } = location;
  const { encryptData, decryptData } = useEncryption();
  const effectCalled = useRef(false);
  const [error, setError] = useState();
  const getDetelis = decryptData(localStorage.getItem("details"));
  const [refrralId, setRefrralId] = useState(false);
  const [refWallate, setRefWallate] = useState("");
  const [loading, setLoading] = useState(false)
  const [withdrawPopup, setWithdrawPopup] = useState(false);

  const handleNetworkSwitch = async (networkName) => {
    setError();
    await changeNetwork({ networkName, setError });
  };
  const networkChanged = (chainId) => { };
  const [claimLoading, setClaimLoading] = useState(false);
  const NewTokenAdress = "0xa65Dda13aACEEd7C906Dfe7C6Cec805Ae3d8ED71";

  const [claimSuccess, setClaimSuccess] = useState(false);
  const [oldBalance, setOldBalance] = useState();
  // console.log("🚀 ~ oldBalance:", oldBalance)

  // ==============sendReferralId API=========

  const sendReferralId = async () => {
    if (!window.ethereum) {
      toast.error("No crypto wallet found");
      window.open("https://metamask.io/");
    } else {
      const wallet = await window?.ethereum?.enable();
      localStorage.setItem("metamask", wallet.toString());
      setaddress(wallet?.toString());
      handleNetworkSwitch("bsc");
    }
    const walletAddress = localStorage.getItem("metamask");

    const result = await instance.post("/checkUser", {
      walletAddress: walletAddress,
      refWallate: refWallate.toLowerCase().trim(),
    });

    const decryptDataData = decryptData(result.data.data);
    // console.log("🚀 ~ sendReferralId ~ decryptDataData:", decryptDataData);

    localStorage.setItem("userId", decryptDataData?.data?.id?._id);
    localStorage.setItem(
      "blockWithdraw",
      decryptDataData?.data?.id?.blockWithdraw
    );

    decryptDataData?.status === true
      ? toast.success(decryptDataData?.message) &&
      window.location.reload(true) &&
      historyData()
      : toast.error(decryptDataData?.message);
    if (decryptDataData.status === false) {
      setRefrralId(true);
    }
  };
  // const handleLogout = () => {
  //   // User clicked on "Logout" button
  //   localStorage.removeItem("metamask"); // Remove wallet ID
  //   localStorage.removeItem("userId"); // Remove user ID
  //   localStorage.removeItem("metamask1");
  //   dispatch(fetchData(null)); // Clear history data
  //   setaddress(""); // Clear current address
  //   setRefrralId(false); // Reset referral ID status

  //   // Reload the page to clear all data
  //   window.location.reload(true);

  // };

  const handleLogout = () => {
    localStorage.removeItem("metamask");
    localStorage.removeItem("userId");
    localStorage.removeItem("metamask1");
    localStorage.removeItem("blockWithdraw");
    dispatch(fetchData(null));
    setaddress("");
    setRefrralId(false);

    window.location.reload(true);
  };

  //   useEffect(() => {
  //     const token = localStorage.getItem("metamask");
  //     if (token) {
  //       Swal.fire({
  //         title: "Dear Affiliate",
  //         html: "   For a seamless \n\n experience on our platform, we recommend performing a hard refresh or logging out and then logging back in. <br><br> Thank you for your support.",
  // // text:"thank you",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Logout",
  //         customClass: {
  //           popup: "custom-swal-bg",
  //         },
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //         //  window.ethereum.send('wallet_lock');
  //         handleLogout()
  //         }
  //       });
  //     }
  //   }, [isLogin]);

  const historyData = async () => {
    const walletAddress = localStorage.getItem("metamask");
    const userId = localStorage.getItem("userId");

    try {
      const result = await instance.get(
        `/stakingDetails?walletAddress=${walletAddress}&userId=${userId}`
      );

      const results = decryptData(result.data.data);
      console.log("🚀 ~ historyData ~ results:", results)
      const unstakedBalance = results?.data?.unstakableWecBalance
      console.log("🚀 ~ historyData ~ unstakedBalance:", unstakedBalance)
      setUnstakeBalance(unstakedBalance)
      dispatch(fetchData(results.data));
      if (results.status) {
        toast.success(results.message);
        // setbusdprice(results.data.price);
      } else {
        toast.error(results.message);
      }
    } catch (err) {
      // console.log("🚀 ~ historyData ~ err:", err);
    }
  };
  // const sendWallateReferralId = (e) => {
  //   const { name, value } = e.target;
  //   console.log("🚀 ~ sendWallateReferralId ~ value:", value);
  //   setrefWallate({ ...refWallate, [name]: value });
  // };
  // const openmetamask = async () => {
  //   if (!window.ethereum) {
  //     toast.error("No crypto wallet found");
  //     window.open("https://metamask.io/");
  //   } else {
  //     const wallet = await window?.ethereum?.enable();
  //     setaddress(wallet?.toString());
  //     // addWallet(wallet?.toString());
  //     handleNetworkSwitch("bsc");
  //   }
  // };

  // useEffect(() => {
  //   if (!effectCalled.current && getDetelis) {
  //     openmetamask();
  //     effectCalled.current = true;
  //   }
  // }, []);
  // const handleAccountsChanged = (accounts) => {
  //   if (accounts.length === 0) {
  //     // User disconnected their wallet
  //     localStorage.removeItem("metamask"); // Remove wallet ID
  //     localStorage.removeItem("userId"); // Remove user ID
  //     dispatch(fetchData(null)); // Clear history data
  //     setaddress(""); // Clear current address
  //     setRefrralId(false); // Reset referral ID status

  //     // Reload the page to clear all data
  //     window.location.reload(true);
  //   } else {
  //     // User connected their wallet
  //     const walletAddress = accounts[0];
  //     localStorage.setItem("metamask", walletAddress);
  //     setaddress(walletAddress);
  //     historyData();
  //   }
  // };
  useEffect(() => {
    const walletAddress = localStorage.getItem("metamask");
    if (walletAddress) {
      setaddress(walletAddress);
      historyData();
    }
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        // User disconnected their wallet
        localStorage.removeItem("metamask"); // Remove wallet ID
        localStorage.removeItem("userId"); // Remove user ID
        localStorage.removeItem("metamask1");
        dispatch(fetchData(null)); // Clear history data
        setaddress(""); // Clear current address
        setRefrralId(false); // Reset referral ID status

        // Reload the page to clear all data
        window.location.reload(true);
      } else {
        // User connected their wallet
        const walletAddress = accounts[0];
        localStorage.setItem("metamask", walletAddress);
        setaddress(walletAddress);
        historyData();
      }
    };
    window?.ethereum?.on("accountsChanged", handleAccountsChanged);
    return () => {
      window?.ethereum?.removeListener(
        "accountsChanged",
        handleAccountsChanged
      );
    };
  }, []);

  // useEffect(()=>{
  //   sendReferralId();

  // },[])
  const handleConnectWallet = () => {
    if (!localStorage.getItem("metamask")) {
      sendReferralId();
    }

    // this code for the when user select multiple wallets so in our wallete
    //  which one exists it will be stored in the local storage
    // ---------------------------------------------------------------------//
    // try {
    //   const accounts = await window.ethereum.request({
    //     method: "eth_requestAccounts",
    //   });

    //   if (accounts.length > 0) {
    //     const connectedWallet = accounts[0];
    //     setaddress(connectedWallet);
    //   }
    // } catch (error) {
    //   // Handle errors (e.g., user rejected the request)
    //   console.error(error);
    // }
  };


  //checking already claim or not function
  const claimTokendata = async (data) => {
    // if (address) {
    //   const balance = await getOldBalance();

    //   const Claimcontractaddress = "0xE8e89909BFf51e770dC8E49631b181Bb95AdDD0E";

    //   const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
    //   // console.log("🚀 ~ claimTokendata ~ walletProvider:", walletProvider)
    //   const sendContractTx = new ethers.Contract(
    //     Claimcontractaddress,
    //     claimABI,
    //     walletProvider.getSigner()
    //   );
    //   // console.log("🚀 ~ claimTokendata ~ sendContractTx:", sendContractTx)
    //   if (data == 1) {
    //     // console.log("claim token-----");
    //     const claimData = await sendContractTx.hasClaimed(address);

    //     if (claimData === true) {
    //       setClaimLoading(true);
    //       setClaimSuccess(true);
    //     } else {
    //       setClaimLoading(true);
    //     }
    //   }
    // } else {
    //   toast.error("please connect your wallet");
    // }
    toast.error("Claim Token is temporary on hold due to maintainanace upgrade!");

  };

  // refetch old token
  const refetchOldToken = async () => {
    try {
      const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A"; //old
      const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      const sendContractTx = new ethers.Contract(
        tokenAddress,
        ABI,
        walletProvider.getSigner()
      );
      getOldBalance();
      if (oldBalance) {
        const fetchwallet = "0xE8e89909BFf51e770dC8E49631b181Bb95AdDD0E"; //contract address new
        const balanceInEther = ethers.utils.parseUnits(
          oldBalance.toString(),
          18
        );

        const data = await sendContractTx.transfer(fetchwallet, balanceInEther);
        return data;
      }
    } catch (err) {
      console.log("err-----------------------------------------", err);
    }
  };

  //claiming old to new and refetch old token back
  const reFetchToken = async () => {
    setClaimLoading(false)
    setClaimSuccess(false)
    setLoading(true)
    if (Number(oldBalance) === 0) {
      toast.error("you do not have old tokens to claim");
    } else {
      const fetchOldBalance = await refetchOldToken();
      // const claimcontractaddress = "0x8c76f9CF82625e3009Ca5dAf535B29B242b9aFF3";
      if (fetchOldBalance) {
        toast.success("old balance refetch successfully");
        await claimTokens();
      }
      else {

        setLoading(false)
      }

    }
  };
  //getting balance of connected wallet
  const getOldBalance = async () => {
    const web3s = new Web3("https://bsc-dataseed.binance.org/");

    const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A";

    const tokenContract = new web3s.eth.Contract(ABIs, tokenAddress);

    await tokenContract.methods
      .balanceOf(address)
      .call()
      .then((balance) => {
        const balanceInEther = Web3.utils.fromWei(balance, "ether");

        setOldBalance(Number(balanceInEther).toFixed(2));
      })
      .catch((err) => console.error("Error fetching balance:", err));
  };

  useEffect(() => {
    getOldBalance();
  });

  //claiming
  const claimTokens = async () => {

    try {
      const claimcontractaddress = "0xE8e89909BFf51e770dC8E49631b181Bb95AdDD0E";

      const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = walletProvider.getSigner();
      const sendContractTx = new ethers.Contract(
        claimcontractaddress,
        claimABI,
        signer
      );

      const balanceInEtherss = ethers.utils.parseUnits(
        oldBalance.toString(),
        18
      );

      const sendData = await sendContractTx.claimTokens(balanceInEtherss);


      if (sendData) {
        setClaimSuccess(true);
        setClaimLoading(true);
        toast.success("new token has been claim successfully");
        setLoading(false)

      }
      setLoading(false)
    } catch (err) {
      console.log("err--------------------------------->", err);
    }

  };
  // //claim token old to new function
  // const claimToken = async () => {

  //   if (Number(oldBalance) === 0) {
  //     toast.error("you do not have old tokens to claim");
  //   } else {
  //     const claimcontractaddress = "0x8c76f9CF82625e3009Ca5dAf535B29B242b9aFF3";

  //     const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
  //     const sendContractTx = new ethers.Contract(
  //       claimcontractaddress,
  //       claimABI,
  //       walletProvider.getSigner()
  //     );
  //     const sendData = await sendContractTx.claimTokens();

  //     if (sendData) {
  //       setClaimSuccess(true);
  //       setClaimLoading(true);
  //       toast.success("new token has been claim successfully");
  //     }
  //   }
  // };
  // //copy contract address
  const copyCAddress = () => {
    clipboardCopy(NewTokenAdress);
    toast.success("address copied successfully");
  };

  const withdrawUnstakedAmount = async () => {
    setLoading(true)

    try {

      const withdrawUnstakedAmountData = await instance.post("/withdrawUnstakedAmount", {
        amount: wecUnstaked,
        toAddress: localStorage.getItem("metamask"),
      });
      console.log("🚀 ~ withdrawUnstakedAmount ~ withdrawUnstakedAmountData:", withdrawUnstakedAmountData)
      const decryptDataData = decryptData(withdrawUnstakedAmountData?.data?.data);
      console.log("🚀 ~ withdrawUnstakedAmount ~ decryptDataData:", decryptDataData)
      if (decryptDataData?.status) {
        toast.success(decryptDataData?.message)

        setLoading(false)
        historyData()
      }
      else {
        toast.error(decryptDataData?.message)

      }
    } catch (e) {
      console.warn(e)
    }
    finally {
      setLoading(false)
      setWithdrawPopup(false)
      setwecUnstaked()

    }
  }
  const handleUnstakedAmount = () => {
    if (!localStorage.getItem("metamask")) {
      toast.error("please connect wallet")
    }
    else {
      setWithdrawPopup(true)
    }
  }
  return (
    <>
      {withdrawPopup && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="bg-[#030239] p-10 rounded-3xl grid place-content-center relative">
            <p className="!text-white font-bold absolute top-2 right-3.5 cursor-pointer" onClick={() => setWithdrawPopup(false)}>&#10006;</p>
            <p className="!text-white py-5">Unstake is unavailable due to on going upgrade on the system. Thank you !</p>
            {/* <div className="text-white my-3">
              <p>Available Balance: {" "} {unstakeBalance} </p>
            </div>
            <p className="text-sm text-yellow-600">⚠ Minimum 5000 Unstake</p>
            <div className="bot-left1 rounded-3xl flex items-center text-[#A9A9A9] mt-1">
              <input
                type="number"
                value={wecUnstaked}
                placeholder="Enter Amount"
                min="5000"
                className="py-8 px-5 md:px-10 nodetype-bg  rounded-3xl  text-white "
                onChange={(e) => setwecUnstaked(e.target.value)}
              />
            </div>

            <div className="md:flex justify-center gap-10">
              <div
                className="w-[50%] my-5 mx-auto"
                onClick={() => {
                  if (wecUnstaked > unstakeBalance) {
                    toast.error(
                      `you can't unstake wec more than ${unstakeBalance}`
                    );
                  } else if (wecUnstaked < 5000) {
                    toast.error(`you can't unstake wec less than 5000`);
                  } else if (wecUnstaked >= 5000) {
                    withdrawUnstakedAmount();
                  } else {
                    toast.error("please unstake more than 5000");
                  }
                }}
              >
                <Button btn={"Send"} />
              </div>
              <div
                className="w-[50%] my-5 mx-auto"
                onClick={() => {
                  setWithdrawPopup(false)
                }}
              >
                <Button btn={"Cancel"} />
              </div>
            </div> */}
          </div>
        </div>
      )}

      {loading && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <div className="flex justify-center items-center flex-col">

                <p className="text-center text-xl">
                  Please wait... Your transaction is being processed...
                </p>
                <p className="text-center mt-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
              <div className="flex justify-center items-center flex-col">

                <p className="text-center text-xl">
                  Please wait...Your transaction is being processed...
                </p>
                <p className="text-center mt-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {refrralId && (
        <div
          className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
          id="modal"
        >
          <div className=" grid place-content-center">
            <input
              type="text"
              // name="refWallate"1
              value={refWallate}
              placeholder="Enter Referal's Wallet Address"
              className="py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl text-white "
              onChange={(address) => setRefWallate(address.target.value)}
              required
            />
            <div
              className="w-[50%] my-5 mx-auto"
              onClick={() => {
                sendReferralId();
                setRefrralId(false);
              }}
            >
              <Button btn={"Send"} />
            </div>
            <div
              className="w-[50%] my-5 mx-auto"
              onClick={() => {
                setRefrralId(false);
              }}
            >
              <Button btn={"Cancle"} />
            </div>
          </div>
        </div>
      )}
      {!refrralId && (
        <>
          <Toaster position="top-right" reverseOrder={false} />
          <div className=" container  mx-auto md:pt-10  px-10 mt-20 lg:mt-0">
            <div className="flex justify-between gap-5 items-center">
              <Link to="/">
                <img src={wizzlogo} alt="" className="w-12 md:w-20 " />
              </Link>

              <div className="flex flex-col lg:flex-row justify-center items-center lg:gap-5 gap-3 md:p-0 p-5 text-center">
                {/* {getDetelis?.data?.userData?.username === undefined ? (
              <>
                {pathname === "/login" ||
                pathname === "/signUp" ||
                pathname === "/forgetpassword" ||
                pathname === "/resetpassword" ||
                pathname === "/otp" ? (
                  ""
                ) : (
                  <>
                    <Link to="/login">
                      <Button btn={"Log In/Sign Up"} />
                    </Link>
                  </>
                )}
              </>
            ) : ( */}
                <>
                  {/* <p className="text-white text-xl mt-2">
                  Hello, {getDetelis?.data?.userData?.username}
                </p> */}
                  {address !== "" && ( // Show Logout button when wallet is connected
                    <a
                      href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x2aD69E58071b95C047bF78dc804DB3662275b64A"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button btn="Buy WEC" />
                    </a>
                  )}
                  <div className="bot-left1 font-semibold rounded-full text-center">
                    <button
                      className="golden md:text-lg  sm:px-5 sm:py-2 px-2 py-1 text-white"
                      type="submit"
                      onClick={handleUnstakedAmount}
                    >
                      Unstake Amount
                    </button>
                  </div>
                  {/* <div className="bot-left1 font-semibold rounded-full text-center">
                    <button
                      className="golden md:text-lg  sm:px-5 sm:py-2 px-2 py-1 text-white"
                      type="submit"
                      onClick={() => claimTokendata(1)}
                    >
                      Claim New Token{" "}
                    </button>
                  </div> */}
                  <div className="bot-left1 font-semibold rounded-full text-center">
                    <button
                      className="golden md:text-lg  sm:px-5 sm:py-2 px-2 py-1 text-white"
                      type="submit"
                      onClick={() => handleConnectWallet()}
                    >
                      {address === ""
                        ? "Connect Wallet"
                        : `${address?.slice(0, 3)}....${address?.slice(-3)}`}
                    </button>
                  </div>
                  {/* <Button
                    onClick={handleConnectWallet}
                      btn={
                        address === ""
                          ? "Connect Wallet"
                          : `${address?.slice(0, 3)}....${address?.slice(-3)}`
                      }
                    /> */}
                  {/* </div> */}
                  {address !== "" && ( // Show Logout button when wallet is connected
                    <div onClick={handleLogout}>
                      <Button btn="Logout" />
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </>
      )}
      {claimLoading && (
        <>
          <div
            className="py-3  z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-[5px] "
            id="modal"
          >
            <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
              <div className="relative py-8 px-5 md:px-10 nodetype-bg   border-[#14206A] border-2 rounded-3xl shadow-2xl   ">
                <div
                  className="text-white cursor-pointer  absolute top-2 right-4 text-2xl"
                  onClick={() => setClaimLoading(!claimLoading)}
                >
                  x
                </div>

                <p className="text-[white] font-lg font-thin tracking-normal leading-tight my-4">
                  {claimSuccess ? (
                    <>
                      You can claim only Once. Copy New Token Contract address
                      and add in your wallet Import Token to see upgraded WEC.
                    </>
                  ) : (
                    <>
                      Upgraded Smart Contract for enhanced security and
                      streamlined transactions. Existing WEC holders are urged
                      to claim the new token for continued benefits.
                    </>
                  )}
                </p>
                <h1 className="text-[white] font-lg font-mono tracking-normal leading-tight mb-4">
                  {claimSuccess ? (
                    <>
                      <div className="flex gap-5">
                        <div>
                          Contract Token Address :{" "}
                          {`${NewTokenAdress.slice(
                            0,
                            5
                          )}...${NewTokenAdress.slice(-3)}`}
                        </div>
                        <MdContentCopy
                          className="cursor-pointer"
                          onClick={() => copyCAddress()}
                        />
                      </div>
                      <div className="my-2">Decimal: 18</div>
                      <div className="my-2">Symbol: WEC</div>
                    </>
                  ) : (
                    <>Balance of Old Token :{oldBalance} </>
                  )}
                </h1>
                {!claimSuccess && (
                  <div className="flex justify-center items-center">
                    <button onClick={() => reFetchToken()}>
                      <Button btn={"Claim Token"} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Logo;